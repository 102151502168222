import React from "react"

const Element4columnsBlogContent = ({data}) => {
    const element = JSON.parse(data.Data);
    var inlineStyle = {};
    if(element.PaddingTop != 0){
        inlineStyle.paddingTop = element.PaddingTop + 'rem';
    }
    if(element.PaddingBottom != 0){
        inlineStyle.paddingBottom = element.PaddingBottom + 'rem';
    } 
    if(element.BGColor != null){
        inlineStyle.backgroundColor = element.BGColor;
    }
  return (
    <div id={`content-${element.ID}`} className="element element4columnsblogcontent">
        <section className={`section`} style={inlineStyle}>
            <div className="container">
                {element.ShowTitle == 1 && ( 
                <h2 className="h3 mb-4 mb-md-5"><span className="underline-7px">{element.Title}</span></h2>
                )}
                <div className="row gutters-10">
                    <div className="col-sm-6 col-lg-3">
                        <div className="grid-item">
                            <div className="grid-item-inner">
                                <img src={element.Col1Image} alt="" />
                                <h4 className="grid-title">{element.Col1Title}</h4>
                                <a href={element.Col1Link} className="grid-caption related-articles-caption">
                                    <div className="inner">
                                        <div className="related-articles-category">{element.Col1HoverTitle}</div>
                                        <div className="related-articles-date">{element.Col1Date}</div>
                                    </div>
                                </a>
                                {/*
                                <button type="button" className="btn-like">
                                    <svg width='30px' height='30px'><title>Like</title><use xlinkHref='/img/icons.svg#icon-heart'></use></svg>
                                </button>
                                 */}
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                        <div className="grid-item">
                            <div className="grid-item-inner">
                                <img src={element.Col2Image} alt="" />
                                <h4 className="grid-title text-black">{element.Col2Title}</h4>
                                <a href={element.Col2Link} className="grid-caption related-articles-caption">
                                    <div className="inner">
                                        <div className="related-articles-category">{element.Col2HoverTitle}</div>
                                        <div className="related-articles-date">{element.Col2Date}</div>
                                    </div>
                                </a>
                                {/*
                                <button type="button" className="btn-like">
                                    <svg width='30px' height='30px'><title>Like</title><use xlinkHref='/img/icons.svg#icon-heart'></use></svg>
                                </button>
                                 */}
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                        <div className="grid-item">
                            <div className="grid-item-inner">
                                <img src={element.Col3Image} alt="" />
                                <h4 className="grid-title">{element.Col3Title}</h4>
                                <a href={element.Col3Link} className="grid-caption related-articles-caption">
                                    <div className="inner">
                                        <div className="related-articles-category">{element.Col2HoverTitle}</div>
                                        <div className="related-articles-date">{element.Col3Date}</div>
                                    </div>
                                </a>
                                {/*
                                <button type="button" className="btn-like">
                                    <svg width='30px' height='30px'><title>Like</title><use xlinkHref='/img/icons.svg#icon-heart'></use></svg>
                                </button>
                                 */}
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                        <div className="grid-item">
                            <div className="grid-item-inner">
                                <img src={element.Col4Image} alt="" />
                                <h4 className="grid-title text-black">{element.Col4Title}</h4>
                                <a href={element.Col4Link} className="grid-caption related-articles-caption">
                                    <div className="inner">
                                        <div className="related-articles-category">{element.Col4HoverTitle}</div>
                                        <div className="related-articles-date">{element.Col4Date}</div>
                                    </div>
                                </a>
                                {/*
                                <button type="button" className="btn-like">
                                    <svg width='30px' height='30px'><title>Like</title><use xlinkHref='/img/icons.svg#icon-heart'></use></svg>
                                </button>
                                 */}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
        {element.LineDivider == 1 ? (
        <div className="container">
          <hr className="hr-black m-0" />
        </div>
        ) : (
            ""
        )}
    </div>
  )
}

export default Element4columnsBlogContent