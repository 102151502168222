import React from "react"
import Masonry from "react-masonry-component"
import SwiperItemsList from '../../components/ContentBuilder/SwiperList/SwiperItemsList'

const ElementFeaturedProducts = ({data}) => {
    const element = JSON.parse(data.Data);  
    var inlineStyle = {};
    if(element.PaddingTop != 0){
        inlineStyle.paddingTop = element.PaddingTop + 'rem';
    }
    if(element.PaddingBottom != 0){
        inlineStyle.paddingBottom = element.PaddingBottom + 'rem';
    } 
    if(element.BGColor != null){
        inlineStyle.backgroundColor = element.BGColor;
    }
  return (
    <div id={`content-${element.ID}`} className="element elementfeaturedproducts">
      <section className={`section`} style={inlineStyle}>
            <div className="container">
                {element.ShowTitle == 1 && (
                    <h2 className="h3 mb-4 mb-md-5"><span className="underline-7px">{element.Title}</span></h2> 
                )} 
                <div>
                    <SwiperItemsList title="" titleClass="title-small" navClass="FeaturedProduct" items={JSON.stringify(element.Items)} />
                </div>
            </div>
        </section>
        {element.LineDivider == 1 ? (
        <div className="container">
          <hr className="hr-black m-0" />
        </div>
        ) : (
            ""
        )}
    </div>
  )
}

export default ElementFeaturedProducts