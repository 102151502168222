import React from "react"

const ElementImageWithTextOverlay = ({ data }) => {
  const element = JSON.parse(data.Data)
  var width_class = 'container'
    if(element.Width === '8Col'){
        width_class = 'col-lg-8 m-auto'
    }else if(element.Width === 'Narrow'){
        width_class = 'container narrow'
    }
  var inlineStyle = {}
  if (element.PaddingTop != 0) {
    inlineStyle.paddingTop = element.PaddingTop + "rem"
  }
  if (element.PaddingBottom != 0) {
    inlineStyle.paddingBottom = element.PaddingBottom + "rem"
  }
  if (element.BGColor != null) {
    inlineStyle.backgroundColor = element.BGColor
  }
  return (
    <div id={`content-${element.ID}`} className="element elementimagewithtextoverlay">
      <section
        className={`section short-article`}
        style={inlineStyle}
      >
        <div className={width_class}>
          <div className="short-article-header">
            <div className="row">
              <div className="col-md-6">
                <div className="image mt-0">
                  {element.Image !== "" && (
                    <img
                      src={element.Image}
                      alt=""
                      className="w-100 img-fluid"
                    />
                  )}
                </div>
              </div>
              <div className="col-md-6 d-flex flex-column justify-content-between">
                <div className="heading mt-4">
                  {element.ShowTitle == 1 && (
                    <h1 className="mb-4">{element.Title}</h1>
                  )}
                  <p
                    className="lead"
                    dangerouslySetInnerHTML={{ __html: element.CustomTitle }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {element.LineDivider == 1 ? (
        <div className="container">
          <hr className="hr-black m-0" />
        </div>
      ) : (
          ""
      )}
    </div>
  )
}

export default ElementImageWithTextOverlay
