import React from "react"
import Masonry from "react-masonry-component"

const ElementFloorplansBlock = ({data}) => {
    const element = JSON.parse(data.Data);    
    var inlineStyle = {};
    if(element.PaddingTop != 0){
        inlineStyle.paddingTop = element.PaddingTop + 'rem';
    }
    if(element.PaddingBottom != 0){
        inlineStyle.paddingBottom = element.PaddingBottom + 'rem';
    } 
    if(element.BGColor != null){
        inlineStyle.backgroundColor = element.BGColor;
    }
  return (
    <div id={`content-${element.ID}`} className="element elementfloorplansblock">
      <section className={`section`} style={inlineStyle}>
            <div className="container">
                {element.ShowTitle == 1 && (
                    <h2 className="title title-long title-long-white text-center mb-5">{element.Title}</h2> 
                )} 
                <div className="row">
                    {element.Items.map((item, idx) => (
                    <div key={`floorplan-item-${idx}`} className="col-md-4 col-sm-6">
                        <div className={`product${item.Sold ? ' sold' : ''}`}>
                            {item.EnquireButtonLink ? (
                            <a href={item.EnquireButtonLink} className="product-img mb-30">
                                <img src={item.GroundFloorImage} alt={item.Name} />
                            </a>
                            ):
                            (
                                <a href="#" className="product-img mb-30">
                                <img src={item.GroundFloorImage} alt={item.Name} />
                                </a>
                            )
                            }
                            <div className="product-body">
                                <h4 className="h3 fz-24 mb-4">
                                    {item.EnquireButtonLink ? (
                                    <a href={item.EnquireButtonLink} className="text-black text-decoration-none underline-7px">{item.Name}</a>
                                    ):(
                                    <a href="#" className="text-black text-decoration-none underline-7px">{item.Name}</a>
                                    )}
                                </h4>
                                <p>{item.Address}</p>
                                <p className="font-recoleta mb-2">{item.Description}</p>
                                <div className="text-forever mb-5">Buyers Guide <strong>{item.Price}</strong></div>
                                <div className="d-flex mb-4">
                                    <div className="product-attr">
                                        <svg width='26px' height='26px' className="d-block mx-auto"><title>Room</title><use xlinkHref='/img/icons.svg#icon-room-solid'></use></svg>
                                        {item.Living}
                                    </div>
                                    <div className="product-attr">
                                        <svg width='26px' height='26px' className="d-block mx-auto"><title>Bed</title><use xlinkHref='/img/icons.svg#icon-bed-solid'></use></svg>
                                        {item.Bed}
                                    </div>
                                    <div className="product-attr">
                                        <svg width='26px' height='26px' className="d-block mx-auto"><title>Bath</title><use xlinkHref='/img/icons.svg#icon-bath-solid'></use></svg>
                                        {item.Bath}
                                    </div>
                                    <div className="product-attr">
                                        <svg width='26px' height='26px' className="d-block mx-auto"><title>Garage</title><use xlinkHref='/img/icons.svg#icon-garage-solid'></use></svg>
                                        {item.Garage}
                                    </div>
                                </div>
                                {item.EnquireButtonLink && (
                                <a href={item.EnquireButtonLink} className="btn btn-black btn-md btn-w210">{item.EnquireButtonTitle}</a>
                                )}
                            </div>
                        </div>
                    </div>
                    ))}
                </div>
            </div>
        </section>
        {element.LineDivider == 1 ? (
        <div className="container">
          <hr className="hr-black m-0" />
        </div>
        ) : (
            ""
        )}
    </div>
  )
}

export default ElementFloorplansBlock