import React from "react"

const ElementContent4Columns = ({data}) => {
    const element = JSON.parse(data.Data);
    var inlineStyle = {};
    if(element.PaddingTop != 0){
        inlineStyle.paddingTop = element.PaddingTop + 'rem';
    }
    if(element.PaddingBottom != 0){
        inlineStyle.paddingBottom = element.PaddingBottom + 'rem';
    } 
    if(element.BGColor != null){
        inlineStyle.backgroundColor = element.BGColor;
    }
    
  return (
    <div id={`content-${element.ID}`} className="element elementcontent4columns">
      <div className={`section`} style={inlineStyle}>
        <div className="container">
            {element.ShowTitle == 1 && (
                <h2 className="title title-long title-long-white text-center">{element.Title}</h2> 
            )} 
            <div className="row">
                <div className="col-sm-6 col-lg-3">
                    <div className="tile-wrap">
                        {element.Col1Image && (
                            <div className="tile-img">
                                {element.Col1CTALink ? (
                                    <a href={element.Col1CTALink} target={`${element.Col1CTANewWindow ? '_blank' : '_self'}`}>
                                        <img alt="" src={element.Col1Image} className="img-fluid w-100" alt=""/>
                                    </a>
                                )
                                : (
                                    <img alt="" src={element.Col1Image} className="img-fluid w-100" alt=""/>
                                    )
                                }
                            </div>
                        )}
                        {element.Col1SubTitle && (<p className="tile-sub-txt fz-14">{element.Col1SubTitle}</p>)}
                        {element.Col1CTALink ? (
                            <h4 className={`${element.Col1H4Heading == 1 ? 'h4' : 'h2'} ${element.Col1HideLine == 1 ? '' : 'underline-5px'} pb-1 text-transform-none tile-title`}>
                                <a href={element.Col1CTALink} target={`${element.Col1CTANewWindow ? '_blank' : '_self'}`} dangerouslySetInnerHTML={{__html: element.Col1Title}}/>
                            </h4>
                        ):(
                            <h4 className={`${element.Col1H4Heading == 1 ? 'h4' : 'h2'} ${element.Col1HideLine == 1 ? '' : 'underline-5px'} pb-1 text-transform-none tile-title`} dangerouslySetInnerHTML={{__html: element.Col1Title}}/>
                        )}
                        <div className="text-muted tile-content" dangerouslySetInnerHTML={{__html: element.Col1Content}}/>
                        {element.Col1CTALink && (
                            <a href={element.Col1CTALink} className="link-primary" target={`${element.Col1CTANewWindow ? '_blank' : '_self'}`}>{element.Col1CTATitle}</a>
                        )}
                    </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                    <div className="tile-wrap">
                        {element.Col2Image && (
                            <div className="tile-img">
                                {element.Col2CTALink ? (
                                    <a href={element.Col2CTALink} target={`${element.Col2CTANewWindow ? '_blank' : '_self'}`}>
                                        <img alt="" src={element.Col2Image} className="img-fluid w-100" alt=""/>
                                    </a>
                                )
                                : (
                                    <img alt="" src={element.Col2Image} className="img-fluid w-100" alt=""/>
                                    )
                                }
                            </div>
                        )}
                        {element.Col2SubTitle && (<p className="tile-sub-txt fz-14">{element.Col2SubTitle}</p>)}
                        {element.Col2CTALink ? (
                            <h4 className={`${element.Col2H4Heading == 1 ? 'h4' : 'h2'} ${element.Col2HideLine == 1 ? '' : 'underline-5px'} pb-1 text-transform-none tile-title`}>
                                <a href={element.Col2CTALink} target={`${element.Col2CTANewWindow ? '_blank' : '_self'}`} dangerouslySetInnerHTML={{__html: element.Col2Title}}/>
                            </h4>
                        ):(
                            <h4 className={`${element.Col2H4Heading == 1 ? 'h4' : 'h2'} ${element.Col2HideLine == 1 ? '' : 'underline-5px'} pb-1 text-transform-none tile-title`} dangerouslySetInnerHTML={{__html: element.Col2Title}}/>
                        )}
                        <div className="text-muted tile-content" dangerouslySetInnerHTML={{__html: element.Col2Content}}/>
                        {element.Col2CTALink && (
                            <a href={element.Col2CTALink} className="link-primary" target={`${element.Col2CTANewWindow ? '_blank' : '_self'}`}>{element.Col2CTATitle}</a>
                        )}
                    </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                    <div className="tile-wrap">
                        {element.Col3Image && (
                            <div className="tile-img">
                                {element.Col3CTALink ? (
                                    <a href={element.Col3CTALink} target={`${element.Col3CTANewWindow ? '_blank' : '_self'}`}>
                                        <img alt="" src={element.Col3Image} className="img-fluid w-100" alt=""/>
                                    </a>
                                )
                                : (
                                    <img alt="" src={element.Col3Image} className="img-fluid w-100" alt=""/>
                                    )
                                }
                            </div>
                        )}
                        {element.Col3SubTitle && (<p className="tile-sub-txt fz-14">{element.Col3SubTitle}</p>)}
                        {element.Col3CTALink ? (
                            <h4 className={`${element.Col3H4Heading == 1 ? 'h4' : 'h2'} ${element.Col3HideLine == 1 ? '' : 'underline-5px'} pb-1 text-transform-none tile-title`}>
                                <a href={element.Col3CTALink} target={`${element.Col3CTANewWindow ? '_blank' : '_self'}`} dangerouslySetInnerHTML={{__html: element.Col3Title}}/>
                            </h4>
                        ):(
                            <h4 className={`${element.Col3H4Heading == 1 ? 'h4' : 'h2'} ${element.Col3HideLine == 1 ? '' : 'underline-5px'} pb-1 text-transform-none tile-title`} dangerouslySetInnerHTML={{__html: element.Col3Title}}/>
                        )}
                        <div className="text-muted tile-content" dangerouslySetInnerHTML={{__html: element.Col3Content}}/>
                        {element.Col3CTALink && (
                            <a href={element.Col3CTALink} className="link-primary" target={`${element.Col3CTANewWindow ? '_blank' : '_self'}`}>{element.Col3CTATitle}</a>
                        )}
                    </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                    <div className="tile-wrap">
                        {element.Col4Image && (
                            <div className="tile-img">
                                {element.Col4CTALink ? (
                                    <a href={element.Col4CTALink} target={`${element.Col4CTANewWindow ? '_blank' : '_self'}`}>
                                        <img alt="" src={element.Col4Image} className="img-fluid w-100" alt=""/>
                                    </a>
                                )
                                : (
                                    <img alt="" src={element.Col4Image} className="img-fluid w-100" alt=""/>
                                    )
                                }
                            </div>
                        )}
                        {element.Col4SubTitle && (<p className="tile-sub-txt fz-14">{element.Col4SubTitle}</p>)}
                        {element.Col4CTALink ? (
                            <h4 className={`${element.Col4H4Heading == 1 ? 'h4' : 'h2'} ${element.Col4HideLine == 1 ? '' : 'underline-5px'} pb-1 text-transform-none tile-title`}>
                                <a href={element.Col4CTALink} target={`${element.Col4CTANewWindow ? '_blank' : '_self'}`} dangerouslySetInnerHTML={{__html: element.Col4Title}}/>
                            </h4>
                        ):(
                            <h4 className={`${element.Col4H4Heading == 1 ? 'h4' : 'h2'} ${element.Col4HideLine == 1 ? '' : 'underline-5px'} pb-1 text-transform-none tile-title`} dangerouslySetInnerHTML={{__html: element.Col4Title}}/>
                        )}
                        <div className="text-muted tile-content" dangerouslySetInnerHTML={{__html: element.Col4Content}}/>
                        {element.Col4CTALink && (
                            <a href={element.Col4CTALink} className="link-primary" target={`${element.Col4CTANewWindow ? '_blank' : '_self'}`}>{element.Col4CTATitle}</a>
                        )}
                    </div>
                </div>
            </div>
        </div>
    </div>
    {element.LineDivider == 1 ? (
        <div className="container">
          <hr className="hr-black m-0" />
        </div>
    ) : (
        ""
    )}
    </div>
  )
}

export default ElementContent4Columns