import React from "react"

const ElementContent3Columns = ({ data }) => {
  const element = JSON.parse(data.Data)
  var width_class = 'container'
    if(element.Width === '8Col'){
        width_class = 'col-lg-8 m-auto'
    }else if(element.Width === 'Narrow'){
        width_class = 'container narrow'
    }
  var inlineStyle = {}
  if (element.PaddingTop != 0) {
    inlineStyle.paddingTop = element.PaddingTop + "rem"
  }
  if (element.PaddingBottom != 0) {
    inlineStyle.paddingBottom = element.PaddingBottom + "rem"
  }
  if (element.BGColor != null) {
    inlineStyle.backgroundColor = element.BGColor
  }
  return (
    <div id={`content-${element.ID}`} className="element elementcontent3columns">
      <div className={`section`} style={inlineStyle}>
        <div className={width_class}>
          {element.ShowTitle == 1 && (
            <h2 className="title title-long title-long-white text-center">
              {element.Title}
            </h2>
          )}
          <div className="row">
            <div className="col-sm-6 col-lg-4">
              <div className="tile-wrap">
                {element.LeftImage && (
                  <div className="tile-img">
                    {element.LeftCTALink ? (
                      <a
                        href={element.LeftCTALink}
                        target={`${
                          element.LeftCTANewWindow ? "_blank" : "_self"
                        }`}
                      >
                        <img
                          alt=""
                          src={element.LeftImage}
                          className="img-fluid w-100"
                        />
                      </a>
                    ) : (
                      <img
                        alt=""
                        src={element.LeftImage}
                        className="img-fluid w-100"
                      />
                    )}
                  </div>
                )}
                {element.LeftSubTitle && (
                  <p className="tile-sub-txt fz-14">{element.LeftSubTitle}</p>
                )}
                {element.LeftCTALink ? (
                  <h4 className={`${element.LeftH4Heading == 1 ? 'h4' : 'h2'} ${element.LeftHideLine == 1 ? '' : 'underline-10px'} pb-2 tile-title`}>
                    <a
                      href={element.LeftCTALink}
                      target={`${
                        element.LeftCTANewWindow ? "_blank" : "_self"
                      }`}
                     dangerouslySetInnerHTML={{__html: element.LeftTitle}}/>
                  </h4>
                ) : (
                  <h4 className={`${element.LeftH4Heading == 1 ? 'h4' : 'h2'} ${element.LeftHideLine == 1 ? '' : 'underline-10px'} pb-2 tile-title`} dangerouslySetInnerHTML={{__html: element.LeftTitle}}/>
                )}
                <div
                  className="text-muted tile-content"
                  dangerouslySetInnerHTML={{ __html: element.LeftContent }}
                />
                {element.LeftCTALink && (
                  <a
                    href={element.LeftCTALink}
                    className="link-primary"
                    target={`${element.LeftCTANewWindow ? "_blank" : "_self"}`}
                  >
                    {element.LeftCTATitle}
                  </a>
                )}
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="tile-wrap">
                {element.MiddleImage && (
                  <div className="tile-img">
                    {element.MiddleCTALink ? (
                      <a
                        href={element.MiddleCTALink}
                        target={`${
                          element.MiddleCTANewWindow ? "_blank" : "_self"
                        }`}
                      >
                        <img
                          alt=""
                          src={element.MiddleImage}
                          className="img-fluid w-100"
                        />
                      </a>
                    ) : (
                      <img
                        alt=""
                        src={element.MiddleImage}
                        className="img-fluid w-100"
                      />
                    )}
                  </div>
                )}
                {element.MiddleSubTitle && (
                  <p className="tile-sub-txt fz-14">{element.MiddleSubTitle}</p>
                )}
                {element.MiddleCTALink ? (
                  <h4 className={`${element.MiddleH4Heading == 1 ? 'h4' : 'h2'} ${element.MiddleHideLine == 1 ? '' : 'underline-10px'} pb-2 tile-title`}>
                    <a
                      href={element.MiddleCTALink}
                      target={`${
                        element.MiddleCTANewWindow ? "_blank" : "_self"
                      }`}
                     dangerouslySetInnerHTML={{__html: element.MiddleTitle}}/>
                  </h4>
                ) : (
                  <h4 className={`${element.MiddleH4Heading == 1 ? 'h4' : 'h2'} ${element.MiddleHideLine == 1 ? '' : 'underline-10px'} pb-2 tile-title`} dangerouslySetInnerHTML={{__html: element.MiddleTitle}}/>
                )}
                <div
                  className="text-muted tile-content"
                  dangerouslySetInnerHTML={{ __html: element.MiddleContent }}
                />
                {element.MiddleCTALink && (
                  <a
                    href={element.MiddleCTALink}
                    className="link-primary"
                    target={`${
                      element.MiddleCTANewWindow ? "_blank" : "_self"
                    }`}
                  >
                    {element.MiddleCTATitle}
                  </a>
                )}
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="tile-wrap">
                {element.RightImage && (
                  <div className="tile-img">
                    {element.RightCTALink ? (
                      <a
                        href={element.RightCTALink}
                        target={`${
                          element.RightCTANewWindow ? "_blank" : "_self"
                        }`}
                      >
                        <img
                          alt=""
                          src={element.RightImage}
                          className="img-fluid w-100"
                        />
                      </a>
                    ) : (
                      <img
                        alt=""
                        src={element.RightImage}
                        className="img-fluid w-100"
                      />
                    )}
                  </div>
                )}
                {element.RightSubTitle && (
                  <p className="tile-sub-txt fz-14">{element.RightSubTitle}</p>
                )}
                {element.RightCTALink ? (
                  <h4 className={`${element.RightH4Heading == 1 ? 'h4' : 'h2'} ${element.RightHideLine == 1 ? '' : 'underline-10px'} pb-2 tile-title`}>
                    <a
                      href={element.RightCTALink}
                      target={`${
                        element.RightCTANewWindow ? "_blank" : "_self"
                      }`}
                     dangerouslySetInnerHTML={{__html: element.RightTitle}}/>
                  </h4>
                ) : (
                  <h4 className={`${element.RightH4Heading == 1 ? 'h4' : 'h2'} ${element.RightHideLine == 1 ? '' : 'underline-10px'} pb-2 tile-title`} dangerouslySetInnerHTML={{__html: element.RightTitle}}/>
                )}
                <div
                  className="text-muted tile-content"
                  dangerouslySetInnerHTML={{ __html: element.RightContent }}
                />
                {element.RightCTALink && (
                  <a
                    href={element.RightCTALink}
                    className="link-primary"
                    target={`${element.RightCTANewWindow ? "_blank" : "_self"}`}
                  >
                    {element.RightCTATitle}
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {element.LineDivider == 1 ? (
        <div className="container">
          <hr className="hr-black m-0" />
        </div>
      ) : (
        ""
      )}
    </div>
  )
}

export default ElementContent3Columns
