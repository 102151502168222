import React from "react"

const ElementImageAndText = ({ data }) => {
    const element = JSON.parse(data.Data)
    var element_style = element.DisplayOptions
    var width_class = 'container'  
    if(element.Width === '8Col'){
        width_class = 'col-lg-8 m-auto'
    }else if(element.Width === 'Narrow'){
        width_class = 'container narrow'
    }
    var inlineStyle = {}

    if (element.PaddingTop != 0) {
      inlineStyle.paddingTop = element.PaddingTop + "rem"
    }
    if (element.PaddingBottom != 0) {
      inlineStyle.paddingBottom = element.PaddingBottom + "rem"
    } 
    if (element.BGColor != null) {
      inlineStyle.backgroundColor = element.BGColor
    }
    
    var image_width_class = 'w-100';
    var image_inlineStyle={}
    if (element.MaxHeight != 0) {
      image_inlineStyle.maxHeight = element.MaxHeight + "px"
      image_width_class = 'w-auto'
    }
  return (
    <div id={`content-${element.ID}`} className="element elementimageandtext">
      <section className={`content-panel`} style={inlineStyle}>
        <div className={width_class}>
          {element.ShowTitle == 1 && (
            <h2 className="title title-long title-long-white text-center">
              {element.Title}
            </h2>
          )}
          {element_style === "left" && (
            <div className="row align-items-center">
              <div className="col-md-6 col-lg">
                {element.Image !== "" && (
                  <img style={image_inlineStyle} src={element.Image} className={`${image_width_class} d-none d-md-block mx-auto`} alt=""></img>
                )}
                  <div className="image d-sm-block d-md-none d-lg-none"
                    style={{ backgroundImage: `url(${element.MobileImage ? element.MobileImage : element.Image})` }}
                  ></div>
                {element.ShowQuote == 1 && (
                  <div className="image-text">
                    <p>{element.Quote}</p>
                    <div className="media">
                      {element.AuthorImage && (
                        <img
                          src={element.AuthorImage}
                          alt=""
                          className="avatar"
                        />
                      )}
                      <div className="media-body">
                        <div>{element.AuthorName}</div>
                        <div className="fz-14">{element.AuthorPosition}</div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-md-6 col-lg-auto">
                <div className="text">
                  <h4
                    className={`h3 mb-4${
                      element.TextOverlay ? " title-left" : ""
                    }`}
                  >
                    {element.CTALink && element.CTALink !== "" ? (
                      <a
                        href={element.CTALink.toString()}
                        className="highlight-black"
                        target={`${element.CTANewWindow ? "_blank" : "_self"}`}
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html: element.CustomTitle,
                          }}
                        />
                      </a>
                    ) : (
                      <a href="#" className="highlight-black">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: element.CustomTitle,
                          }}
                        />
                      </a>
                    )}
                  </h4>
                  <div dangerouslySetInnerHTML={{ __html: element.Content }} />
                  {element.CTALink && element.CTALink !== "" && (
                    <a
                      href={element.CTALink.toString()}
                      className="btn btn-black text-decoration-none mt-5"
                      target={`${element.CTANewWindow ? "_blank" : "_self"}`}
                    >
                      {element.CTATitle}
                    </a>
                  )}
                </div>
              </div>
            </div>
          )}
          {element_style === "right" && (
            <div className="row align-items-center">
              <div className="col-md-6 col-lg order-md-2">
                {element.Image !== "" && (
                  <img style={image_inlineStyle} src={element.Image} className={`${image_width_class} d-none d-md-block mx-auto`}></img>
                )}
                  <div
                    className="image d-sm-block d-md-none d-lg-none"
                    style={{ backgroundImage: `url(${element.MobileImage ? element.MobileImage : element.Image})` }}
                  ></div>
                {element.ShowQuote == 1 && (
                  <div className="image-text">
                    <p>{element.Quote}</p>
                    <div className="media">
                      {element.AuthorImage && (
                        <img
                          src={element.AuthorImage}
                          alt=""
                          className="avatar"
                        />
                      )}
                      <div className="media-body">
                        <div>{element.AuthorName}</div>
                        <div className="fz-14">{element.AuthorPosition}</div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-md-6 col-lg-auto order-md-1">
                <div className="text">
                  <h4 className={`h3 mb-4`}>
                    {element.CTALink && element.CTALink !== "" ? (
                      <a
                        href={element.CTALink.toString()}
                        className="highlight-black"
                        target={`${element.CTANewWindow ? "_blank" : "_self"}`}
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html: element.CustomTitle,
                          }}
                        />
                      </a>
                    ) : (
                      <a href="#" className="highlight-black">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: element.CustomTitle,
                          }}
                        />
                      </a>
                    )}
                  </h4>
                  <div dangerouslySetInnerHTML={{ __html: element.Content }} />
                  {element.CTALink && element.CTALink !== "" && (
                    <a
                      href={element.CTALink.toString()}
                      className="btn btn-black text-decoration-none mt-5"
                      target={`${element.CTANewWindow ? "_blank" : "_self"}`}
                    >
                      {element.CTATitle}
                    </a>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
      {element.LineDivider == 1 ? (
        <div className="container">
          <hr className="hr-black m-0" />
        </div>
      ) : (
        ""
      )}
    </div>
  )
}

export default ElementImageAndText
