import React from "react"

const ElementIntroBlockWithAuthor = ({data}) => {
    const element = JSON.parse(data.Data);
    var width_class = 'container'
    if(element.Width === '8Col'){
        width_class = 'col-lg-8 m-auto'
    }else if(element.Width === 'Narrow'){
        width_class = 'container narrow'
    }
    var inlineStyle = {};
    if(element.PaddingTop != 0){
        inlineStyle.paddingTop = element.PaddingTop + 'rem';
    }
    if(element.PaddingBottom != 0){
        inlineStyle.paddingBottom = element.PaddingBottom + 'rem';
    } 
    if(element.BGColor != null){
        inlineStyle.backgroundColor = element.BGColor;
    }
    return (
            <div id={`content-${element.ID}`} className="element elementintroblockwithauthor">
                <section className={`section short-article`} style={inlineStyle}>
                    <div className={width_class}>
                        {element.ShowTitle == 1 && ( 
                            <h2 className="title title-long title-long-white text-center">{element.Title}</h2> 
                        )} 
                        <div className={`short-article-header`}>
                            <div className="row">
                                <div className="col-md-6">
                                    {element.Image && (
                                        <div className="image">
                                            <img src={element.Image} alt="" className="w-100 img-fluid" />
                                        </div>
                                    )}
                                </div>
                                <div className="col-md-6 d-flex flex-column justify-content-between">
                                    <div className="heading">
                                        <h1 className="mb-4" dangerouslySetInnerHTML={{__html: element.CustomTitle}}/>
                                        <div className="lead" dangerouslySetInnerHTML={{__html: element.Content}}/>
                                    </div>
                                    <div className="footnote">
                                        {element.PinkText && (<div className="text-forever font-weight-bold">{element.PinkText}</div>)}
                                        {element.GreyText1 && (<div className="text-gray"><strong>{element.GreyText1}</strong> <span className="font-recoleta">{element.Text1}</span></div>)}
                                        {element.GreyText2 && (<div className="text-gray"><strong>{element.GreyText2}</strong> <span className="font-recoleta">{element.Text2}</span></div>)}
                                        {element.GreyText3 && (<div className="text-gray"><strong>{element.GreyText2}</strong> <span className="font-recoleta">{element.Text3}</span></div>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {element.LineDivider == 1 ? (
                    <div className="container">
                        <hr className="hr-black m-0" />
                    </div>
                ) : (
                    ""
                )}
            </div>
        )
}

export default ElementIntroBlockWithAuthor