import React, { useState, useEffect, useRef } from "react"
import Masonry from "react-masonry-component"
import Carousel, { Modal, ModalGateway } from "react-images"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation } from "swiper/core"

SwiperCore.use([Navigation])

const ElementGallery = ({ data }) => {
  const element = JSON.parse(data.Data)
  var inlineStyle = {}
  if (element.PaddingTop != 0) {
    inlineStyle.paddingTop = element.PaddingTop + "rem"
  }
  if (element.PaddingBottom != 0) {
    inlineStyle.paddingBottom = element.PaddingBottom + "rem"
  }
  if (element.BGColor != null) {
    inlineStyle.backgroundColor = element.BGColor
  }
  const useLightbox = element.LinkOption === "Lightbox" ? true : false
  const AlwaysShowTileTitle = element.AlwaysShowTileTitle == 1
  const TileBlackTitle = element.TileBlackTitle == 1
  const Gallery = element.Gallery
  var GalleryStyle = 'GalleryTiles';
  if(Gallery.Style){
      GalleryStyle = Gallery.Style;
  }
  var GalleryAlbums = []
  if (typeof Gallery.Items !== "undefined") {
    GalleryAlbums = Object.keys(Gallery.Items).map(key => Gallery.Items[key])
    var lightboxItems = []
    var i = 0
    for (; GalleryAlbums[i]; ) {
      let album = GalleryAlbums[i]
      var j = 0
      for (; album[j]; ) {
        lightboxItems.push({
          source: album[j].ImagePath,
          caption: album[j].Caption,
        })
        j++
      }
      i++
    }
  }
  const params = {
    slidesPerView: 1,
    spaceBetween: 15,
    navigation: {
      prevEl: ".swiper-button-prev-gallery",
      nextEl: ".swiper-button-next-gallery",
    },
  }

  const [loaded, setLoaded] = useState(false)
  const imgElement = useRef(null)

  return (
    <div id={`content-${element.ID}`} className="element elementgallery">
      <section className={`section`} style={inlineStyle}>
        <div className="container">
          {element.ShowTitle == 1 && (
            <h2 className="title title-long title-long-white text-center">
              {element.Title}
            </h2>
          )}
          {GalleryStyle === "GalleryTiles" && (
            <>
              {GalleryAlbums.map((album, idx) => (
                <Masonry
                  key={`gallery-album-${idx}`}
                  className="grid GalleryTiles grid-gallery mb-5"
                  options={{
                    columnWidth: ".grid-sizer",
                    transitionDuration: 0,
                    percentPosition: true,
                  }}
                >
                  <div className="grid-sizer"></div>
                  {album.map((item, idx) => (
                    <a
                      key={`gallery-item-${idx}`}
                      href={useLightbox ? item.ImagePath : item.Link}
                      className={`grid-item pointer${
                        AlwaysShowTileTitle ? " showtitle" : ""
                      }${TileBlackTitle ? " text-black" : ""}`}
                      data-fancybox={`content-${element.ID}`}
                      rel={`content-${element.ID}`}
                      data-width={
                        loaded && imgElement.current.src.slice(-3) === "svg"
                          ? imgElement.current.naturalWidth * 9
                          : ""
                      }
                      data-height={
                        loaded && imgElement.current.src.slice(-3) === "svg"
                          ? imgElement.current.naturalHeight * 9
                          : ""
                      }
                    >
                      <div className="grid-item-inner">
                        <img
                          src={item.ImagePath}
                          alt=""
                          ref={imgElement}
                          onLoad={() => setLoaded(true)}
                        />
                        <div className="grid-caption">
                          <button type="button" className="btn-like">
                            <span className="sr-only">Like</span>
                          </button>
                          <h4>{item.Caption}</h4>
                        </div>
                      </div>
                    </a>
                  ))}
                </Masonry>
              ))}
            </>
          )}
          {GalleryStyle === "Gallery5Images" && (
            <div className="gallery-swiper Gallery5Images">
              <Swiper {...params}>
                {GalleryAlbums.map((album, idx) => (
                  <SwiperSlide key={`gallery-album-${idx}`}>
                    <Masonry
                      className="grid grid-gallery"
                      options={{
                        columnWidth: ".grid-sizer",
                        transitionDuration: 0,
                        percentPosition: true,
                      }}
                    >
                      <div className="grid-sizer w-25"></div>
                      {album.map((item, idx) => (
                        <a
                          key={`gallery-item-${idx}`}
                          href={useLightbox ? item.ImagePath : item.Link}
                          className={`grid-item pointer ${
                            idx == 0 ? "w-50" : "w-25"
                          }${AlwaysShowTileTitle ? " showtitle" : ""}${
                            TileBlackTitle ? " text-black" : ""
                          }`}
                          data-fancybox={`content-${element.ID}`}
                          data-width={
                            loaded && imgElement.current.src.slice(-3) === "svg"
                              ? imgElement.current.naturalWidth * 9
                              : ""
                          }
                          data-height={
                            loaded && imgElement.current.src.slice(-3) === "svg"
                              ? imgElement.current.naturalHeight * 9
                              : ""
                          }
                        >
                          <div className="grid-item-inner">
                            <img
                              src={item.ImagePath}
                              alt=""
                              ref={imgElement}
                              onLoad={() => setLoaded(true)}
                            />
                            <div className="grid-caption">
                              <button type="button" className="btn-like">
                                <span className="sr-only">Like</span>
                              </button>
                              <h4>{item.Caption}</h4>
                            </div>
                          </div>
                        </a>
                      ))}
                    </Masonry>
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className="swiper-button-prev-gallery"></div>
              <div className="swiper-button-next-gallery"></div>
            </div>
          )}
          {GalleryStyle === "Gallery6Images" && (
            <div className="gallery-swiper Gallery6Images">
              <Swiper {...params}>
                {GalleryAlbums.map((album, idx) => (
                  <SwiperSlide key={`gallery-album-${idx}`}>
                    <Masonry
                      className="grid grid-gallery"
                      options={{
                        columnWidth: ".grid-sizer",
                        transitionDuration: 0,
                        percentPosition: true,
                      }}
                    >
                      <div className="grid-sizer w-25"></div>
                      {album.map((item, idx) => (
                        <a
                          key={`gallery-item-${idx}`}
                          href={useLightbox ? item.ImagePath : item.Link}
                          className={`grid-item${
                            AlwaysShowTileTitle ? " showtitle" : ""
                          }${TileBlackTitle ? " text-black" : ""} pointer ${
                            idx == 0 || idx == 3 ? "w-50" : "w-25"
                          }`}
                          data-fancybox={`content-${element.ID}`}
                          data-width={
                            loaded && imgElement.current.src.slice(-3) === "svg"
                              ? imgElement.current.naturalWidth * 9
                              : ""
                          }
                          data-height={
                            loaded && imgElement.current.src.slice(-3) === "svg"
                              ? imgElement.current.naturalHeight * 9
                              : ""
                          }
                        >
                          <div className="grid-item-inner">
                            <img
                              src={item.ImagePath}
                              alt=""
                              ref={imgElement}
                              onLoad={() => setLoaded(true)}
                            />
                            <div className="grid-caption">
                              <button type="button" className="btn-like">
                                <span className="sr-only">Like</span>
                              </button>
                              <h4>{item.Caption}</h4>
                            </div>
                          </div>
                        </a>
                      ))}
                    </Masonry>
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className="swiper-button-prev-gallery"></div>
              <div className="swiper-button-next-gallery"></div>
            </div>
          )}
        </div>
      </section>
      {element.LineDivider == 1 ? (
        <div className="container">
          <hr className="hr-black m-0" />
        </div>
      ) : (
        ""
      )}
    </div>
  )
}

export default ElementGallery
