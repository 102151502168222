import React from "react"

const ElementNewsletter = ({data}) => {
    const element = JSON.parse(data.Data);
    var bgClass = element.BackgroundClass;
    var width_class = 'container'
    if(element.Width === '8Col'){
        width_class = 'col-lg-8 m-auto'
    }else if(element.Width === 'Narrow'){
        width_class = 'container narrow'
    }
    var inlineStyle = {};
    if(element.PaddingTop != 0){
        inlineStyle.paddingTop = element.PaddingTop + 'rem';
    }
    if(element.PaddingBottom != 0){
        inlineStyle.paddingBottom = element.PaddingBottom + 'rem';
    } 
    if(element.BGColor != null){
        inlineStyle.backgroundColor = element.BGColor;
    }
  return (
    <div id={`content-${element.ID}`} className="element elementnewsletter">
      <section className={`section ${bgClass}`} style={inlineStyle}>
            <div className={width_class}>
                {element.ShowTitle == 1 && ( 
                <h2 className="text-center mb-2">{element.Title}</h2>
                )}
                { element.IntroText !== '' && (<p className="text-center" dangerouslySetInnerHTML={{__html: element.IntroText}}/>)}
                <div className="row justify-content-center">
                    <div className="col-xl-10">
                        <div className="row gutters-10 mb-5">
                            <div className="col-sm-6 col-md">
                                <div className="form-group form-group-lg">
                                    <input type="text" className="form-control" id="inputName2" placeholder="Name" />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md">
                                <div className="form-group form-group-lg">
                                    <input type="text" className="form-control" id="inputEmail2" placeholder="Email *" />
                                </div>
                            </div>
                            <div className="col-md-auto text-center">
                                <div className="form-group">
                                    <button type="submit" className="btn btn-black btn-md btn-w210">SUBMIT</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {element.LineDivider == 1 ? (
            <div className="container">
                <hr className="hr-black m-0" />
            </div>
        ) : (
            ""
        )}
    </div>
  )
}

export default ElementNewsletter