import React from "react"

const ElementInstagramFeed = ({data}) => {
    const element = JSON.parse(data.Data);
    var bgClass = element.BackgroundClass;
    var width_class = 'container'
    if(element.Width === '8Col'){
        width_class = 'col-lg-8 m-auto'
    }else if(element.Width === 'Narrow'){
        width_class = 'container narrow'
    }
    var inlineStyle = {};
    if(element.PaddingTop != 0){
        inlineStyle.paddingTop = element.PaddingTop + 'rem';
    }
    if(element.PaddingBottom != 0){
        inlineStyle.paddingBottom = element.PaddingBottom + 'rem';
    } 
    if(element.BGColor != null){
        inlineStyle.backgroundColor = element.BGColor;
    }
  return (
    <div id={`content-${element.ID}`} className="element elementinstagramfeed">
        <section className={`section ${bgClass}`} style={inlineStyle}>
            <div className={width_class}>
                {element.ShowTitle == 1 && ( 
                <h2 className="h3 mb-4 mb-md-5"><span className="underline-7px">{element.Title}</span></h2>
                )}
                <div className="row gutters-10" dangerouslySetInnerHTML={{ __html: element.EmbedCode }}/>
            </div>
        </section>
        {element.LineDivider == 1 ? (
        <div className="container">
          <hr className="hr-black m-0" />
        </div>
        ) : (
            ""
        )}
    </div>
  )
}

export default ElementInstagramFeed
