import React from "react"
import Masonry from "react-masonry-component"

const ElementRelatedBlogArticles = ({data}) => {
    const element = JSON.parse(data.Data);  
    var inlineStyle = {};
    if(element.PaddingTop != 0){
        inlineStyle.paddingTop = element.PaddingTop + 'rem';
    }
    if(element.PaddingBottom != 0){
        inlineStyle.paddingBottom = element.PaddingBottom + 'rem';
    } 
    if(element.BGColor != null){
        inlineStyle.backgroundColor = element.BGColor;
    }
  return (
    <div id={`content-${element.ID}`} className="element elementrelatedblogarticles">
      <section className={`section`} style={inlineStyle}>
            <div className="container">
                {element.ShowTitle == 1 && (
                    <h2 className="h3 mb-4 mb-md-5"><span className="underline-7px">{element.Title}</span></h2> 
                )} 
                <div className="row gutters-10">
                    {element.Items.map((item, idx) => (
                    <div key={`blogpost-${idx}`} className="col-sm-6 col-lg-3">
                        <div className="grid-item">
                            <div className="grid-item-inner">
                                <img src={item.Image} alt="" />
                                <h4 className={`grid-title${idx % 2 == 0 ? '' : ' text-black'}`} dangerouslySetInnerHTML={{ __html: item.CustomTitle ? item.CustomTitle : item.Title }}/>
                                <a href={item.Link} className="grid-caption related-articles-caption">
                                    <div className="inner">
                                        <div className="related-articles-category">{item.Category}</div>
                                        <div className="related-articles-date">{item.Date}</div>
                                    </div>
                                </a>
                                <button type="button" className="btn-like">
                                    <svg width='30px' height='30px'><title>Like</title><use xlinkHref='/img/icons.svg#icon-heart'></use></svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    ))}
                </div>
            </div>
        </section>
        {element.LineDivider == 1 ? (
        <div className="container">
          <hr className="hr-black m-0" />
        </div>
        ) : (
            ""
        )}
    </div>
  )
}

export default ElementRelatedBlogArticles