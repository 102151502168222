import React from "react"

const ElementContent = ({data}) => {
    const element = JSON.parse(data.Data);
    var width_class = 'container'
    if(element.Width === '8Col'){
        width_class = 'col-lg-8 m-auto'
    }else if(element.Width === 'Narrow'){
        width_class = 'container narrow'
    }
    var inlineStyle = {};
    if(element.PaddingTop != 0){
        inlineStyle.paddingTop = element.PaddingTop + 'rem';
    }
    if(element.PaddingBottom != 0){
        inlineStyle.paddingBottom = element.PaddingBottom + 'rem';
    } 
    if(element.BGColor != null){
        inlineStyle.backgroundColor = element.BGColor;
    }
    var bg_img = element.BackgroundImage
    if (bg_img !== "") {
        inlineStyle.backgroundImage = `url(${bg_img})`
        inlineStyle.backgroundRepeat = `no-repeat`
        inlineStyle.backgroundSize = `cover`      
    }
  return (
    <div id={`content-${element.ID}`} className="element elementcontent">
        <section className={`section`} style={inlineStyle}>
                <div className={width_class}>
                    {element.ShowTitle == 1 && (
                        <h2 className="title title-long title-long-primary text-center">{element.Title}</h2> 
                    )} 
                    <div className="row justify-content-center">
                        <div className="col-xl-8 col-lg-10">
                            <div className="lead text-center mb-0" dangerouslySetInnerHTML={{__html: element.Content}} />
                        </div>
                    </div>
                </div>
        </section>
        {element.LineDivider == 1 ? (
            <div className="container">
              <hr className="hr-black m-0" />
            </div>
        ) : (
            ""
        )}
    </div>
  )
}

export default ElementContent
