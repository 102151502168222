import React from "react"
import Masonry from "react-masonry-component"

const ElementFeaturedProduct = ({data}) => {
    const element = JSON.parse(data.Data);   
    var inlineStyle = {};
    if(element.PaddingTop != 0){
        inlineStyle.paddingTop = element.PaddingTop + 'rem';
    }
    if(element.PaddingBottom != 0){
        inlineStyle.paddingBottom = element.PaddingBottom + 'rem';
    } 
    if(element.BGColor != null){
        inlineStyle.backgroundColor = element.BGColor;
    }
  return (
    <div id={`content-${element.ID}`} className="element elementfeaturedproduct">
      <section className={`section`} style={inlineStyle}>
            <div className="container">
                {element.ShowTitle == 1 && ( 
                    <h2 className="title title-long title-long-white text-center">{element.Title}</h2> 
                )}
                <div className="product-2columns">
                    <div className="row gutters-10">
                        <div className="col-md-6">
                            {element.Image && (
                            <div className="product-2columns-img p-4" style={{ backgroundImage: `url(${element.Image})` }}>
                                <img src={element.OverlayLogo} alt="" className="img-fluid" />
                            </div>
                            )}
                        </div>
                        <div className="col-md-6">
                            <div className="product-2columns-caption p-4">
                                <div className="w-100">
                                    <h4 className="h3 fz-24 mb-4">
                                        <a href={element.EnquireButtonLink ? element.EnquireButtonLink : '#'} className="underline-7px text-black text-decoration-none">{element.Name}</a>
                                    </h4>
                                    <p>{element.Address}</p>
                                    <div className="text-forever mb-5">Buyers Guide <strong>{element.Price}</strong></div>
                                    <div className="d-flex justify-content-center mb-5">
                                        <div className="product-attr no-border">
                                            <div className="icon-border"><svg width='32px' height='32px' className="d-block mx-auto"><title>Room</title><use xlinkHref='/img/icons.svg#icon-room-border'></use></svg></div>
                                            {element.Living}
                                        </div>
                                        <div className="product-attr no-border">
                                            <div className="icon-border"><svg width='32px' height='32px' className="d-block mx-auto"><title>Bed</title><use xlinkHref='/img/icons.svg#icon-bed-border'></use></svg></div>
                                            {element.Bed}
                                        </div>
                                        <div className="product-attr no-border">
                                            <div className="icon-border"><svg width='32px' height='32px' className="d-block mx-auto"><title>Bath</title><use xlinkHref='/img/icons.svg#icon-bath-border'></use></svg></div>
                                            {element.Bath}
                                        </div>
                                        <div className="product-attr no-border">
                                            <div className="icon-border"><svg width='32px' height='32px' className="d-block mx-auto"><title>Garage</title><use xlinkHref='/img/icons.svg#icon-garage-border'></use></svg></div>
                                            {element.Garage}
                                        </div>
                                    </div>
                                    <a href={element.EnquireButtonLink ? element.EnquireButtonLink : '#'} className="btn btn-black btn-md btn-w210">{element.EnquireButtonTitle}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {element.LineDivider == 1 ? (
        <div className="container">
          <hr className="hr-black m-0" />
        </div>
        ) : (
            ""
        )}
    </div>
  )
}

export default ElementFeaturedProduct