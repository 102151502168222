import React from "react"

const ElementOneColumnText = ({data}) => {
    const element = JSON.parse(data.Data);
    var width_class = 'container'
    if(element.Width === '8Col'){
        width_class = 'col-lg-8 m-auto'
    }else if(element.Width === 'Narrow'){
        width_class = 'container narrow'
    }
    var bgClass = element.BackgroundClass;
    var inlineStyle = {};
    if(element.PaddingTop != 0){
        inlineStyle.paddingTop = element.PaddingTop + 'rem';
    }
    if(element.PaddingBottom != 0){
        inlineStyle.paddingBottom = element.PaddingBottom + 'rem';
    }
    if(element.BGColor != null){
        inlineStyle.backgroundColor = element.BGColor;
    }
    var content = element.Content.toString();
    //content = content.replace(/\[image/g, "\<image").replace(/\"\]/g, "\/\>");
  return (
    <div id={`content-${element.ID}`} className="element elementonecolumntext">        
        <div className={`section ${bgClass}`} style={inlineStyle}>
            {element.ShowTitle == 1 && ( 
                <h2 className="title title-long title-long-white text-center">{element.Title}</h2> 
            )} 
            <div className={width_class} dangerouslySetInnerHTML={{__html: content}}/>
        </div>
        {element.LineDivider == 1 ? (
        <div className="container">
          <hr className="hr-black m-0" />
        </div>
        ) : (
            ""
        )}
    </div>
  )
}

export default ElementOneColumnText
