import React from "react"

const ElementContactForm = ({data}) => {
    const element = JSON.parse(data.Data);
    var inlineStyle = {};
    if(element.PaddingTop != 0){
        inlineStyle.paddingTop = element.PaddingTop + 'rem';
    }
    if(element.PaddingBottom != 0){
        inlineStyle.paddingBottom = element.PaddingBottom + 'rem';
    } 
    if(element.BGColor != null){
        inlineStyle.backgroundColor = element.BGColor;
    }
  return (
    <div id={`content-${element.ID}`} className="element elementbenefit">
      <section className={`section bg-primary`} style={inlineStyle}>
        <div className="container">
            {element.ShowTitle == 1 && ( 
                <h2 className="title title-long title-long-white text-center">{element.Title}</h2> 
            )}
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-10">
              <div className="lead text-center" dangerouslySetInnerHTML={{__html: element.Content}} />
            </div>
          </div>
          <div className="row">
            <h3>ElementContactForm</h3>
          </div>
        </div>
      </section>
      {element.LineDivider == 1 ? (
        <div className="container">
          <hr className="hr-black m-0" />
        </div>
      ) : (
        ""
      )}
    </div>
  )
}

export default ElementContactForm
