import React from "react"

const ElementCalloutBlock = ({ data }) => {
  const element = JSON.parse(data.Data)
  var width_class = 'container'
    if(element.Width === '8Col'){
        width_class = 'col-lg-8 m-auto'
    }else if(element.Width === 'Narrow'){
        width_class = 'container narrow'
    }
  var inlineStyle = {}
  if (element.PaddingTop != 0) {
    inlineStyle.paddingTop = element.PaddingTop + "rem"
  }
  if (element.PaddingBottom != 0) {
    inlineStyle.paddingBottom = element.PaddingBottom + "rem"
  }
  if (element.BGColor != null) {
    inlineStyle.backgroundColor = element.BGColor
  }
  return (
    <div id={`content-${element.ID}`} className="element elementcalloutblock">
    <section className={`section box-left`} style={inlineStyle}>
      <div className={width_class}>
        {element.ShowTitle == 1 && (
          <h2 className="title title-long title-long-white text-center">
            {element.Title}
          </h2>
        )}
        <div className="h1 mb-4">
          {element.Content && (
            <div dangerouslySetInnerHTML={{ __html: element.Content }} />
          )}
        </div>
        {element.CTALink && (
          <a
            href={element.CTALink}
            className="link-primary"
            target={`${element.CTANewWindow ? "_blank" : "_self"}`}
          >
            {element.CTATitle}
          </a>
        )}
      </div>
    </section>
      {element.LineDivider == 1 ? (
        <div className="container">
          <hr className="hr-black m-0" />
        </div>
      ) : (
        ""
      )}
    </div>
  )
}

export default ElementCalloutBlock
