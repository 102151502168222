import React, { useState } from "react"
import { handleSaveSubscribe } from "../../services/acsubscribe"

const ElementEnquireForm = ({data}) => {
    const element = JSON.parse(data.Data);
    var bgClass = element.BackgroundClass;
    var inlineStyle = {};
    if(element.PaddingTop != 0){
        inlineStyle.paddingTop = element.PaddingTop + 'rem';
    }
    if(element.PaddingBottom != 0){
        inlineStyle.paddingBottom = element.PaddingBottom + 'rem';
    } 
    if(element.BGColor != null){
        inlineStyle.backgroundColor = element.BGColor;
    }
    const [formValid, setFormValid] = useState(true);
    const [submitBtnLabel, setSubmitBtnLabel] = useState('Submit');
    const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
    
    const ListID = element.ListID;
    
    var [formData, setFormData] = useState({
        InterestedIn: '',
        Name: '',
        Email: '',
        Phone: '',
        Postcode: '',
        ContactByEmail: 0,
        ContactByPhone: 0,
        YourNeeds: '',
        Message: '',
        SubscribeNewsletter: 0,
        ListID: ListID,
    });
    const handleUpdate = (event) => {
        let _name = event.target.name;
        let _value;
        let checkboxes = ['ContactByEmail', 'ContactByPhone', 'SubscribeNewsletter'];
        if(checkboxes.includes(_name)){
            _value = event.target.checked ? 1 : 0;
        }else{
            _value = event.target.value;
        }        
        formData[_name] = _value;
        setFormData(formData);
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitBtnLabel('Submitting');
        setSubmitBtnDisabled(true)
        handleSaveSubscribe(formData)
    }
  return (
    <div id={`content-${element.ID}`} className="element elementenquireform">
      <section className={`section ${bgClass}`} style={inlineStyle}>
            <div className="container">
                {element.ShowTitle == 1 && ( 
                <h2 className="text-center mb-5">{element.Title}</h2>
                )}
                <form className="enquire-form"  method="post" onSubmit={e => { handleSubmit(e) }}>  
                <div className="row justify-content-center">
                    <div className="col-xl-8 col-lg-10">
                        <div className="form-group mb-5 custom-dropdown">
                            <h3>I am interested in*</h3>
                              <select
                                name="InterestedIn"
                                id="InterestedIn"
                                className="select-dropdown"
                                data-style="true"
                                autoComplete="off"
                                title="I am interested in*"
                                defaultValue="First home buyer"
                                required onChange={e => { handleUpdate(e) }}
                              >                
                                    <option value="Building a home">Building a home</option>
                                    <option value="Knock Down Rebuild">Knock-down rebuild</option>
                                    <option value="House & Land">House & Land</option>
                              </select>
                        </div>
                        <div className="row gutters-10 mb-5">
                            <div className="col-sm-6">
                                <div className="form-group form-group-lg">
                                    <input placeholder="Name" name="Name" type="text" className="form-control" id="inputName" required onChange={e => { handleUpdate(e) }} />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group form-group-lg">
                                    <input placeholder="Email *" name="Email" type="text" className="form-control" id="inputEmail" required onChange={e => { handleUpdate(e) }} />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group form-group-lg">
                                    <input placeholder="Mobile" name="Phone" type="text" className="form-control" id="inputMobile" required onChange={e => { handleUpdate(e) }}/>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group form-group-lg">
                                    <input placeholder="Suburb/Postcode" name="Postcode" type="text" className="form-control" id="inputSuburb" required onChange={e => { handleUpdate(e) }}/>
                                </div>
                            </div>
                        </div>
                        <div className="mb-5">
                            <h3>Preferred Contact Method*</h3>
                            <div className="row gutters-10 align-items-center mb-2">
                                <div className="col-6">
                                    <div className="custom-control custom-checkbox custom-checkbox-heart">
                                        <input name="ContactByEmail" type="checkbox" className="custom-control-input" id="ContactByEmail" onChange={e => { handleUpdate(e) }} />
                                        <label className="custom-control-label" htmlFor="ContactByEmail">Email</label>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="custom-control custom-checkbox custom-checkbox-heart">
                                        <input name="ContactByPhone" type="checkbox" className="custom-control-input" id="ContactByPhone" onChange={e => { handleUpdate(e) }} />
                                        <label className="custom-control-label" htmlFor="ContactByPhone">Phone</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group mb-5 custom-dropdown">
                            <h3>What best describes you?</h3>
                              <select
                                name="YourNeeds"
                                id="YourNeeds"
                                className="select-dropdown"
                                data-style="true"
                                autoComplete="off"
                                title="What best describes you?"
                                defaultValue="First home buyer"
                                onChange={e => { handleUpdate(e) }}
                              >                   
                                    <option value="First Homebuyer">First Home Buyer</option>
                                    <option value="Downsizer">Downsizer</option>
                                    <option value="Upsizer">Upsizer</option>
                                    <option value="Investor">Investor</option>
                              </select>
                        </div>
                        <div className="mb-5">
                            <h3>Enquiry*</h3>
                            <div className="row gutters-10 align-items-center mb-2">
                                <textarea name="Message" className="form-control" rows="4" required onChange={e => { handleUpdate(e) }}></textarea>
                            </div>
                        </div>
                        <div className="mb-5">                            
                            <div className="row gutters-10 align-items-center mb-2">                                
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" name="SubscribeNewsletter" id="SubscribeNewsletter" className="custom-control-input" onChange={e => { handleUpdate(e) }}/>
                                    <label htmlFor="SubscribeNewsletter" className="custom-control-label">I want to receive amazing offers and exciting news</label>
                                </div>
                            </div>
                        </div>
                        <div className="text-center">
                            <button type="submit" className="btn btn-black btn-md btn-w210" disabled={submitBtnDisabled}>{submitBtnLabel}</button>
                        </div>
                    </div>
                </div>
                </form>
            </div>
        </section>
        {element.LineDivider == 1 ? (
        <div className="container">
          <hr className="hr-black m-0" />
        </div>
        ) : (
            ""
        )}
    </div>
  )
}

export default ElementEnquireForm
