import React, { useEffect, useState } from "react"
import config from "../../config/config"
import { getUser, setUser, isLoggedIn } from "../../services/auth"
import axios from "axios"
import $ from 'jquery'
$(document).ready(function(){
    setTimeout(function(){
        var passwordField = $('form.userform div.password')
        $('form.userform input[name=create_account]').change(function(e){
            if(passwordField.length > 0){
                if($(this).is(":checked")) {
                    passwordField.show()
                }else{
                    passwordField.hide()
                }
            }
        })
    }, 2000)    
})

const ElementUserForm = ({data}) => {
    const element = JSON.parse(data.Data)
    var inlineStyle = {}
    if(element.PaddingTop != 0){
        inlineStyle.paddingTop = element.PaddingTop + 'rem'
    }
    if(element.PaddingBottom != 0){
        inlineStyle.paddingBottom = element.PaddingBottom + 'rem'
    } 
    if(element.BGColor != null){
        inlineStyle.backgroundColor = element.BGColor
    }
    const host = config.host
    const pageID = element.ID
    const formType = 'ElementForm'
    const [error, setError] = useState(null)
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const pageURL = window.location.href
            let page_url_field = document.getElementsByName('page_url')
            if(page_url_field.length > 0){
                page_url_field[0].value = pageURL
            }
            const handleSubmit = e => {
                e.preventDefault()
                const formData = new FormData(e.target)
                formData.append('PageID', element.ID)
                formData.append('FormType', formType)
                axios.post(config.host + "/tribecaapi/userform/", formData).then(response => {
                    let _response_data = response.data
                    if(_response_data.Success == 1){
                        let member = _response_data.Member
                        let valid_member = typeof member
                        if (valid_member === 'object') {
                            setUser(member)
                        }
                        if(_response_data.RedirectLink){
                            if (typeof window !== 'undefined') {
                                window.location.href = _response_data.RedirectLink
                            }
                        }
                    }else if(_response_data.Success == 0){
                        setError(_response_data.Message)
                        let top = document.getElementById('userform').offsetTop
                        if(typeof top !== 'undefined'){
                            let scrollTo = top + 600
                            console.log(scrollTo)
                            window.scroll({
                                top: scrollTo,
                                behavior: "smooth"
                            })
                        }
                    }
                }).catch(function (error) {
                })
            }

            const form = window.document.getElementsByTagName('form')[0]
            form.addEventListener('submit', e => handleSubmit(e), false)
            
            // clean up event
            return form.removeEventListener('submit', e => handleSubmit(e))
        }
        return () => {}
    }, [])
  return (
    <div id={`content-${element.ID}`} className="element elementuserform">
      <section className={`section`} style={inlineStyle}>
        <div className="container">
            {element.ShowTitle == 1 && ( 
                <h2 className="title title-long title-long-white text-center">{element.Title}</h2> 
            )}
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-10">
                {error && (<p className="message error text-danger">{error}</p>)}
                <div id="userform">
                    <div dangerouslySetInnerHTML={{__html: element.HTMLForm}} />              
                </div>
            </div>
          </div>
        </div>
      </section>
      {element.LineDivider == 1 ? (
        <div className="container">
          <hr className="hr-black m-0" />
        </div>
      ) : (
        ""
      )}
    </div>
  )
}

export default ElementUserForm
