import React from "react"
import { Parallax } from "react-parallax"

const ElementCTABlock = ({ data }) => {
  const element = JSON.parse(data.Data)
  var bg_img = element.BackgroundImage
  var element_style = element.Style
  var inlineStyle = {}
  if (element.PaddingTop != 0) {
    inlineStyle.paddingTop = element.PaddingTop + "rem"
  }
  if (element.PaddingBottom != 0) {
    inlineStyle.paddingBottom = element.PaddingBottom + "rem"
  }
  if (bg_img !== "") {
    inlineStyle.backgroundImage = `url(${bg_img})`
    inlineStyle.backgroundRepeat = `no-repeat`
    inlineStyle.backgroundSize = `cover`
  }
  if (element.BGColor != null) {
    inlineStyle.backgroundColor = element.BGColor
  }
  var useParallax = element.UseParallax == 1

  return (
    <>
    {useParallax ? (
    <Parallax bgImage={bg_img} strength={250}>
      <div id={`content-${element.ID}`} className="element elementctablock">
        {element_style === "LeftAlign" && (
          <section
            className={`section pattern-circle paralax-section`}
            style={inlineStyle}
          >
            <div className="container">
              {element.ShowTitle == 1 && (
                <h2 className="title title-long title-long-white text-center">
                  {element.Title}
                </h2>
              )}
              <div className="row align-items-center">
                <div className="col-sm-6">
                  <h3 className="mb-4 mb-sm-0">
                    <span
                      className="underline-10px"
                      dangerouslySetInnerHTML={{ __html: element.LeftTitle }}
                    />
                  </h3>
                </div>
                <div className="col-sm-6 col-lg-4 col-xl-3">
                  {element.Content && (
                    <div
                      className="font-recoleta fz-20"
                      dangerouslySetInnerHTML={{ __html: element.Content }}
                    />
                  )}
                  {element.CTALink && (
                    <a
                      href={element.CTALink}
                      className="btn btn-black"
                      target={`${element.CTANewWindow ? "_blank" : "_self"}`}
                    >
                      {element.CTATitle}
                    </a>
                  )}
                </div>
              </div>
            </div>
          </section>
        )}
        {element_style === "CenterAlign" && (
          <section className={`section pattern-circle`} style={inlineStyle}>
            <div className="container">
              {element.ShowTitle == 1 && (
                <h2 className="title title-long title-long-white text-center">
                  {element.Title}
                </h2>
              )}
              <div className="text-center">
                <h3 className="mb-5">
                  <span
                    className="underline-10px"
                    dangerouslySetInnerHTML={{ __html: element.LeftTitle }}
                  />
                </h3>
                {element.Content && (
                  <div
                    className="font-recoleta fz-20"
                    dangerouslySetInnerHTML={{ __html: element.Content }}
                  />
                )}
                {element.CTALink && (
                  <a
                    href={element.CTALink}
                    className="btn btn-black"
                    target={`${element.CTANewWindow ? "_blank" : "_self"}`}
                  >
                    {element.CTATitle}
                  </a>
                )}
              </div>
            </div>
          </section>
        )}
        {element.LineDivider == 1 ? (
          <div className="container">
            <hr className="hr-black m-0" />
          </div>
        ) : (
          ""
        )}
      </div>
    </Parallax>
    ):(
        <div id={`content-${element.ID}`} className="element elementctablock">
        {element_style === "LeftAlign" && (
          <section
            className={`section pattern-circle`}
            style={inlineStyle}
          >
            <div className="container">
              {element.ShowTitle == 1 && (
                <h2 className="title title-long title-long-white text-center">
                  {element.Title}
                </h2>
              )}
              <div className="row align-items-center">
                <div className="col-sm-6">
                  <h3 className="mb-4 mb-sm-0">
                    <span
                      className="underline-10px"
                      dangerouslySetInnerHTML={{ __html: element.LeftTitle }}
                    />
                  </h3>
                </div>
                <div className="col-sm-6 col-lg-4 col-xl-3">
                  {element.Content && (
                    <div
                      className="font-recoleta fz-20"
                      dangerouslySetInnerHTML={{ __html: element.Content }}
                    />
                  )}
                  {element.CTALink && (
                    <a
                      href={element.CTALink}
                      className="btn btn-black"
                      target={`${element.CTANewWindow ? "_blank" : "_self"}`}
                    >
                      {element.CTATitle}
                    </a>
                  )}
                </div>
              </div>
            </div>
          </section>
        )}
        {element_style === "CenterAlign" && (
          <section className={`section pattern-circle`} style={inlineStyle}>
            <div className="container">
              {element.ShowTitle == 1 && (
                <h2 className="title title-long title-long-white text-center">
                  {element.Title}
                </h2>
              )}
              <div className="text-center">
                <h3 className="mb-5">
                  <span
                    className="underline-10px"
                    dangerouslySetInnerHTML={{ __html: element.LeftTitle }}
                  />
                </h3>
                {element.Content && (
                  <div
                    className="font-recoleta fz-20"
                    dangerouslySetInnerHTML={{ __html: element.Content }}
                  />
                )}
                {element.CTALink && (
                  <a
                    href={element.CTALink}
                    className="btn btn-black"
                    target={`${element.CTANewWindow ? "_blank" : "_self"}`}
                  >
                    {element.CTATitle}
                  </a>
                )}
              </div>
            </div>
          </section>
        )}
        {element.LineDivider == 1 ? (
          <div className="container">
            <hr className="hr-black m-0" />
          </div>
        ) : (
          ""
        )}
      </div>
    )}
    </>
  )
}

export default ElementCTABlock
